import React from "react";
import { StaticQuery, graphql,withPrefix } from "gatsby";
import { Link } from "gatsby";
import Menu from "./Menu";
import MenuMobile from "./MenuMobile";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { MaterialUi } from "mdi-material-ui";
import Logo from "./Logo"
import SearchBox from "./SearchBox";
import withStyles from "@material-ui/styles/withStyles";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  menuButton: {
    color: theme.palette.primary.contrastText,
  },
});

const Header = withStyles(styles)((props) => {
  const { classes } = props;
  
  return (
    <AppBar id="appBar">
      <Toolbar>
        <Grid alignItems="center" direction="row" container justify="space-between" spacing={0}>
          <Grid item >
            <Link to="/">
              <Grid container direction="row"
                justify="flex-start"
                alignItems="center">
                <Logo />
                <Hidden smDown>
                  <Button className={classes.menuButton}>{props.data.site.siteMetadata.title.toUpperCase()}</Button>
                </Hidden>
              </Grid>
            </Link>
          </Grid>
          <Grid item>
            <Grid alignItems="center" container justify="flex-end" spacing={1}>
              <Grid item>
                <SearchBox />
              </Grid>
              <Grid item>
                <Hidden smDown>
                  <Typography component="span" variant="caption">
                    <Menu />
                  </Typography>
                </Hidden>
                <Hidden mdUp>
                  <MenuMobile />
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
});

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            contact {
              email
              phone
            }
          }
        }
      }
    `}
    render={(data) => <Header data={data} />}
  />
);
