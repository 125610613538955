import React from "react";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import withStyles from "@material-ui/styles/withStyles";
import { fade } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { navigate } from "gatsby";

const ENTER_KEY = 13;

const styles = theme => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "auto",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "auto",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
});

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  onChange = event => {
    this.setState({
      value: event.target.value,
    });
  };

  onEnter = event => {
    console.log(event.target.value);

    const isEnterPressed =
      event.which === ENTER_KEY || event.keyCode === ENTER_KEY;
    if (isEnterPressed) {
      navigate("/search", {
        state: {
          q: event.target.value,
        },
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
          onChange={this.onChange}
          onKeyPress={this.onEnter}
          value={this.state.value}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SearchBox);
